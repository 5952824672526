<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group
          :label="`*`+$t('Price')+`:`"
          label-cols-md="12"
        >
          <validation-provider
            #default="{ errors }"
            name="Price"
            rules="required"
          >
            <b-form-input
              v-model="value.price"
              :state="errors.length > 0 ? false : null"
              type="number"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="* Quantity:"
          label-cols-md="12"
        >
          <validation-provider
            #default="{ errors }"
            name="Quantity"
            rules="required"
          >
            <b-form-input
              v-model="value.quantity"
              :state="errors.length > 0 ? false : null"
              type="number"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- <b-form-checkbox
            v-model="value.sell_a_sLot"
            value="sellAsLot"
            class="custom-control-primary mt-1"
          >
            Sell as lot
          </b-form-checkbox> -->
        </b-form-group>
      </b-col>
    </b-row>
    <Variation v-model="value" />
    <!-- <WhoseSale v-model="value" /> -->
    <!-- <b-row>
        <b-col md="12">
          <b-form-group label="* Payment options:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Payment options"
              rules="required"
            >
              <b-form-checkbox
                v-model="value.payment"
                value="paypal"
                class="custom-control-primary"
                style="margin-top: 8px;"
              >
                PayPal
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-form-group
              v-if="value.payment"
              label="Email address for receiving payment:"
              label-cols-md="6"
              class="mt-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="value.email_paypal"
                  :state="errors.length > 0 ? false : null"
                  type="email"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Return options:" label-cols-md="12">
            <b-form-checkbox
              v-model="value.return_options"
              value="IRA"
              class="custom-control-primary"
              style="margin-top: 8px;"
            >
              International returns accepted
            </b-form-checkbox>
            <template v-if="value.return_options">
              <b-form-group
                label="After receiving the item, your buyer should contact you within:"
                label-cols-md="9"
                class="mt-1"
              >
                <v-select
                  v-model="value.return_time"
                  label="title"
                  :options="returnTimesOptions"
                />
              </b-form-group>
              <b-form-group
                label="Return shipping fee will be paid by:"
                label-cols-md="4"
              >
                <v-select
                  v-model="value.return_paid"
                  label="title"
                  :options="returnShippingFeePaidOptions"
                />
              </b-form-group>
              <b-form-checkbox
                v-model="value.exchange"
                value="ROEA"
                class="custom-control-primary"
              >
                Replacement or exchange available
              </b-form-checkbox>
            </template>
          </b-form-group>
        </b-col>
      </b-row> -->
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  // BInputGroup,
  // BFormCheckbox,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Variation from '@/views/pages/product_v2/product-add/components/Variation.vue'
// import WhoseSale from '@/views/pages/product/product-add/components/WhoseSale.vue'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    // BInputGroup,
    // BFormCheckbox,
    Variation,
    // WhoseSale,
    BRow,
    BCol,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      email,
      returnShippingFeePaidOptions: [
        { title: 'Buyer' },
        { title: 'Seller (Free Returns)' },
      ],
      returnTimesOptions: [{ title: '30 days' }, { title: '60 days' }],
    }
  },
  methods: {
    onEditorChange({ html }) {
      this.value.product_description = html
      this.product_description_length = html.length
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
