<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group :label="`*`+$t('Product name')+`:`" label-cols-md="12">
          <validation-provider
            #default="{ errors }"
            name="Product name"
            rules="required"
          >
            <b-form-input
              v-model="value.name"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('Product name')"
              @input="generateSlug(value.name)"
            />
            <span class="count-input-length">
              {{ value.name ? value.name.length : 0 }}/100
            </span>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <!-- <b-form-group label="Slug:" label-cols-md="12">
          <b-form-input
            v-model="value.slug"
            placeholder="Slug"
            @input="generateSlug(value.slug)"
          />
        </b-form-group> -->
        <b-form-group label="*SKU:" label-cols-md="12">
          <validation-provider #default="{ errors }" name="SKU" rules="required">
            <b-form-input
              v-model="value.sku"
              :state="errors.length > 0 ? false : null"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group :label="`*`+$t('Category')+`:`" label-cols-md="12" class="align-items-center">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="Edit2Icon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="value.category_name"
          v-b-modal.modal-xl
          :placeholder="$t('Select product category')"
        />
      </b-input-group>
      <small v-if="categoryIdErrorText !== null" class="text-danger">{{
        categoryIdErrorText
      }}</small>
      <popup-add-cate v-model="value" />
    </b-form-group>
    <b-row>
      <b-col cols="4">
        <b-form-group
          :label="$t('Trademark')+`:`"
          label-cols-md="12"
        >
          <v-select
            v-if="trademarkOptions"
            v-model="value.brand_id"
            label="name"
            :reduce="x => x.id"
            :options="trademarkOptions"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="$t('Country of origin')+`:`"
          label-cols-md="12"
        >
          <v-select
            v-if="originOptions"
            v-model="value.origin_id"
            label="name"
            :reduce="x => x.id"
            :options="originOptions"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="$t('Supplier')+`:`"
          label-cols-md="12"
        >
          <v-select
            v-if="supplierOptions"
            v-model="value.supplier_id"
            label="name"
            :reduce="x => x.id"
            :options="supplierOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group :label="$t('Attribute')+`:`" label-cols-md="12">
      <div style="position: absolute; right: 0; top: -30px;">
        <b-form-checkbox
          v-model="value.short_content[0].isShow"
          checked="true"
          class="custom-control-success"
          name="check-button"
          switch
        />
      </div>
      <div class="bg-gray">
        <b-form-group :label="$t('Options')" label-cols-md="12">
          <b-form class="repeater-form" @submit.prevent="repeateAgain()">
            <div
              v-for="(item, index) in value.short_content[0].value"
              :id="String(index)"
              :key="index"
              class="mb-1"
            >
              <b-row>
                <b-col md="4">
                  <b-form-input
                    v-model="value.short_content[0].value[index].title"
                    placeholder=""
                  />
                </b-col>
                <b-col md="7">
                  <b-form-input
                    v-model="value.short_content[0].value[index].content"
                    placeholder=""
                  />
                </b-col>
                <b-col md="1">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="Trash2Icon" class="mr-25" />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </b-form-group>

        <b-form-group label-cols-md="12" label="">
          <div
            v-if="value.short_content[0].value.length < 5"
            class="fade-browse"
            @click="repeateAgain()"
          >
            <feather-icon icon="PlusCircleIcon" class="mr-25" />
            {{ $t('More attribute') }} ({{ value.short_content[0].value.length }}/5)
          </div>
        </b-form-group>
      </div>
    </b-form-group>
    <b-form-group :label="$t('Short content') + `:`" label-cols-md="12">
      <div style="position: absolute; right: 0; top: -30px;">
        <b-form-checkbox
          v-model="value.short_content[1].isShow"
          checked="true"
          class="custom-control-success"
          name="check-button"
          switch
        />
      </div>
      <b-form-textarea
        v-model="value.short_content[1].value"
        rows="6"
        max-rows="6"
        no-resize
      />
    </b-form-group>
    <b-form-group :label="`*`+$t('Product description')+`:`" label-cols-md="12">
      <quill-editor
        id="content"
        ref="qEdit"
        :content="value.content"
        :options="snowOption"
        style="min-height: 180px;"
        @change="onEditorChange($event)"
      />
      <input
        id="getFile"
        type="file"
        @change="uploadFunction($event)"
      >
      <span class="count-textarea-length">
        {{ product_description_length }}/1000
      </span>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  // BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PopupAddCate from './components/PopupAddCate.vue'

export default {
  components: {
    BFormTextarea,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    quillEditor,
    // BLink,
    PopupAddCate,
    BButton,
    BForm,
    BFormCheckbox,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    categoryIdErrorText: {
      type: String,
      default: null,
    },
    trademarkOptions: {
      type: Array,
      default() {
        return []
      },
    },
    originOptions: {
      type: Array,
      default() {
        return []
      },
    },
    supplierOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      required,
      categoryOptions: null,
      editorOption: {
        placeholder: '',
      },
      snowOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['clean'],
              ['image'],
            ],
            handlers: {
              image: () => {
                document.getElementById('getFile').click()
              },
            },
          },
        },
      },
      product_description_length: 0,
    }
  },
  created() {
    this.loadCategoryList()
  },
  methods: {
    generateSlug(name) {
      this.value.slug = this.convertToSlug(name)
    },
    onEditorChange({ html }) {
      this.value.content = html
      this.product_description_length = html.length
    },
    async loadCategoryList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/categories?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categoryOptions = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    repeateAgain() {
      this.value.short_content[0].value.push({
        label: null,
        content: null,
      })
    },
    removeItem(index) {
      this.value.short_content[0].value.splice(index, 1)
    },
    uploadFunction(e) {
      const vm = this
      const image = e.target.files[0]
      if (image) {
        if (image.size > 5000000) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Picture is too large! < 5MB',
            },
          })
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(image)
          reader.onload = ev => {
            vm.postImge(ev.target.result)
          }
        }
      }
    },
    async postImge(image) {
      const range = this.$refs.qEdit.quill.getSelection()
      const params = {
        site_id: JSON.parse(localStorage.getItem('siteID')).id,
        type_table: 'blog',
        type: 'desktop',
        avatar: image,
        table_field: 'ckeditor',
        in_use: 1,
      }
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_IMG_URL}/images_only`,
        params,
      )
      this.$refs.qEdit.quill.insertEmbed(
        range.index,
        'image',
        res.data.data.image,
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
.form-row {
  align-items: center;
}
</style>
<style lang="scss" scoped>
#getFile {
  display: none;
}
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
button svg {
  margin-right: 0 !important;
}
.wpx-100 {
  width: 150px;
}
</style>
